import React, { useRef, useState, useEffect } from "react"
import D3Chart from "./bar-D3Chart"
import Card from "react-bootstrap/Card"

import { connect } from "react-redux"

const mapStateToProps = ({ peren_ha, onset_ha, noncrop_ha, isPercentage }) => {
  return { peren_ha, onset_ha, noncrop_ha, isPercentage }
}

const ChartWrapper = ({
  data,
  peren_ha,
  onset_ha,
  noncrop_ha,
  isPercentage,
}) => {
  const chartArea = useRef(null)
  const [chart, setChart] = useState(null)
  const dataState = {
    peren_ha,
    onset_ha,
    noncrop_ha,
  }

  useEffect(() => {
    var filteredData = JSON.parse(JSON.stringify(data))

    for (var d in filteredData) {
      if (!peren_ha) {
        delete d.peren_ha
      }
      if (!onset_ha) {
        delete d.onset_ha
      }
      if (!noncrop_ha) {
        delete d.noncrop_ha
      }
    }

    if (!chart) {
      setChart(
        new D3Chart(chartArea.current, filteredData, dataState, isPercentage)
      )
    } else {
      chart.update(filteredData, dataState, isPercentage)
    }
  }, [chart, data, dataState, isPercentage, noncrop_ha, onset_ha, peren_ha])

  return (
    <div>
      <div className="chart-area" ref={chartArea}></div>
      <Card id="bar-chart-tooltip" bg="primary"></Card>
    </div>
  )
}

export default connect(mapStateToProps)(ChartWrapper)
