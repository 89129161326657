import React, { Component } from "react"
import { Geomap } from "d3plus-react"
import { navigate } from "gatsby"
// import topojson_admin1 from "../data/topojson/kenya/admin1/kenya_admin1_2017_onset.json"
import topojson_admin1_kenya from "../data/topojson/kenya/merged-topojson.json"
import topojson_admin2_kenya from "../data/topojson/kenya/admin2/kenya_admin2_2017_onset.json"

import topojson_admin1_rwanda from "../data/topojson/rwanda/admin1/rwanda_admin1_2017_onset.json"
import topojson_admin2_rwanda from "../data/topojson/rwanda/admin2/rwanda_admin2_2017_onset.json"
// import LocationsData from "../data/source/source-simple.json"
import LocationsData from "../data/source/locations.json"
import { selectAll } from "d3-selection"
import "./map.scss"
import { connect } from "react-redux"

const mapStateToProps = ({ admin_level, adm0, adm1, adm2 }) => {
  return { admin_level, adm0, adm1, adm2 }
}

class Map extends Component {
  componentWillUnmount() {
    selectAll(".d3plus-tooltip").remove()
  }

  render() {
    let topojson
    let pcode_key, en_key


    switch(this.props.adm0.toString()){
      case "189":
        switch (this.props.admin_level) {
          case 0:
            topojson = topojson_admin1_rwanda
            pcode_key = "ADM1_PCODE"
            en_key = "ADM1_EN"
            break
          case 1:
            topojson = topojson_admin2_rwanda
            pcode_key = "ADM2_PCODE"

            en_key = "ADM2_EN"
            break
          default:
            topojson = topojson_admin2_rwanda
            pcode_key = "ADM2_PCODE"

            en_key = "ADM2_EN"
            break
        }
        break
      case "KE":
        switch (this.props.admin_level) {
          case 0:
            topojson = topojson_admin1_kenya
            pcode_key = "ADM1_PCODE"
            en_key = "ADM1_EN"
            break
          case 1:
            topojson = topojson_admin2_kenya
            pcode_key = "ADM2_PCODE"
            en_key = "ADM2_EN"
            break
          default:
            topojson = topojson_admin2_kenya
            pcode_key = "ADM2_PCODE"
            en_key = "ADM2_EN"
            break
        }
        
        break
    }

    return (
      <Geomap
        className="map"
        config={{
          data: LocationsData,
          groupBy: "id",
          shapeConfig: {
            hoverOpacity: 1,
            Path: {
              fill: d => {
                switch (this.props.admin_level) {
                  case 2:
                    if (d.ADM2_PCODE === this.props.adm2) {
                      return "rgba(62, 168, 168, 0.9)"
                    } else {
                      return "rgba(0, 0, 0, 0.75)"
                    }
                  case 0:
                    if (d.ADM1_PCODE) {
                      return "rgba(0, 0, 0, 0.75)"
                    } else {
                      return "rgba(62, 168, 168,0.2)"
                    }
                  default:
                    return "rgba(0, 0, 0, 0.75)"
                }
              },
              stroke: d => {
                switch (this.props.admin_level) {
                  case 0:
                    if (d.ADM1_PCODE) {
                      return "rgba(255,255,255,0.5)"
                    } else {
                      return "rgba(62, 168, 168,1)"
                    }
                  default:
                    return "rgba(255,255,255,0.5)"
                }
              },
            },
          },
          loadingHTML: "",
          on: {
            "click.shape": d => {
              if (d.ADM2_PCODE) {
                if (d.ADM2_EN) {
                  const url =
                    d.ADM2_EN.toLowerCase().replace(/ /g, "") +
                    "-" +
                    d.ADM1_PCODE.toLowerCase().replace(/ /g, "")
                  navigate(`/${url}`)
                }
              } else if (d.ADM1_PCODE) {
                if (d.ADM1_EN) {
                  const url =
                    d.ADM1_EN.toLowerCase().replace(/ /g, "") +
                    "-" +
                    d.ADM0_PCODE.toLowerCase().replace(/ /g, "")
                  navigate(`/${url}`)
                }
              } else {
                if (d.ADM0_EN) {
                  const url = d.ADM0_EN.toLowerCase().replace(/ /g, "")
                  navigate(`/${url}`)
                }
              }

              selectAll(".d3plus-tooltip").remove()
            },
          },
          downloadButton: false,
          detectResize: true,
          topojsonId: d => {
            
            return d.properties[pcode_key]
          },
          topojson: topojson,
          topojsonFilter: d => {
            switch (this.props.admin_level) {
              case 1:
                return d.properties.ADM1_PCODE === this.props.adm1
              case 2:
                return d.properties.ADM1_PCODE === this.props.adm1
              default:
                return true
            }
          },
          zoom: false,
          legend: null,
          tiles: false,
          ocean: "transparent",
          tooltip: true,
          tooltipConfig: {
            background: "white",
            borderRadius: "5px",
            title: d => {
              return d[en_key]
            },
            height: "2em",
          },
          label: d => {
            return d.name
          },
        }}
      />
    )

    
  }
}

export default connect(mapStateToProps)(Map)
