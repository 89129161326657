import React, { useRef, useState, useEffect } from "react"
import D3Chart from "./line-D3Chart"
import Card from "react-bootstrap/Card"
import "./line.scss"

const ChartWrapper = ({ data }) => {
  const chartArea = useRef(null)
  const [chart, setChart] = useState(null)

  useEffect(() => {
    if (!chart) {
      setChart(new D3Chart(chartArea.current, data))
    } else {
      chart.update(data)
    }
  }, [chart, data])

  return (
    <div>
      <div className="chart-area" ref={chartArea}></div>
      <Card id="tooltip" bg="primary"></Card>
    </div>
  )
}

export default ChartWrapper
