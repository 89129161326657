import React, {useEffect} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProfileTabs from "../components/profile-tabs"
import { navigate } from "gatsby"
import { connect } from "react-redux"


const mapStateToProps = ({ adm0, adm1, adm2, id, isLoggedin }) => {
  return { adm0, adm1, adm2, id, isLoggedin }
}

const mapDispatchToProps = dispatch => {
  return {
    select_admin: (
      id,
      url,
      adm0,
      adm1,
      adm2,
      level_0,
      level_1,
      level_2,
      admin_level,
      subcontinent
    ) =>
      dispatch({
        type: `SELECT_ADMIN`,
        id,
        url,
        adm0,
        adm1,
        adm2,
        level_0,
        level_1,
        level_2,
        admin_level,
        subcontinent,
      }),
  }
}

const ProfilePage = ({ select_admin, pageContext, isLoggedin }) => {
  
  useEffect(() => {
    if (!isLoggedin) {
      navigate("/")
    }
  });

  const eastAfricaCountries = ["kenya", "rwanda", "tanzania", "ethiopia"]
  const southAsiaCountries = ["nepal", "india"]

  var subcontinent
  if (
    eastAfricaCountries.find(
      country => country === pageContext.level_0.toLowerCase()
    )
  ) {
    subcontinent = "East Africa"
  } else if (
    southAsiaCountries.find(
      country => country === pageContext.level_0.toLowerCase()
    )
  ) {
    subcontinent = "South Asia"
  }

  select_admin(
    pageContext.id,
    pageContext.url,
    pageContext.adm0,
    pageContext.adm1,
    pageContext.adm2,
    pageContext.level_0,
    pageContext.level_1,
    pageContext.level_2,
    pageContext.level,
    subcontinent
  )

  return (
    <div>
      <Layout>
        <SEO title="Profile" />

        <ProfileTabs context={pageContext} />
      </Layout>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage)
