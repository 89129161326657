import * as d3 from "d3"

const MARGIN = { TOP: 10, BOTTOM: 50, LEFT: 80, RIGHT: 10 }
const WIDTH = 500 - MARGIN.LEFT - MARGIN.RIGHT
const HEIGHT = 300 - MARGIN.TOP - MARGIN.BOTTOM

class D3Chart {
  constructor(element, data) {
    let vis = this

    function responsivefy(svg) {
      // get container + svg aspect ratio
      var container = d3.select(svg.node().parentNode),
        width = parseInt(svg.style("width")),
        height = parseInt(svg.style("height")),
        aspect = width / height

      // add viewBox and preserveAspectRatio properties,
      // and call resize so that svg resizes on inital page load
      svg
        .attr("viewBox", "0 0 " + width + " " + height)
        .attr("perserveAspectRatio", "xMinYMid")
        .call(resize)

      // to register multiple listeners for same event type,
      // you need to add namespace, i.e., 'click.foo'
      // necessary if you call invoke this function for multiple svgs
      // api docs: https://github.com/mbostock/d3/wiki/Selections#on
      d3.select(window).on("resize." + container.attr("id"), resize)

      // get width of container and resize svg to fit it
      function resize() {
        var targetWidth = parseInt(container.style("width"))
        svg.attr("width", targetWidth)
        svg.attr("height", Math.round(targetWidth / aspect))
      }
    }

    // Setup chart dimensions
    vis.g = d3
      .select(element)
      .append("svg")
      .attr("width", WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
      .attr("height", HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
      .call(responsivefy)
      .append("g")
      .attr("transform", `translate(${MARGIN.LEFT}, ${MARGIN.TOP})`)

    // Scale x-axis
    vis.x = d3.scaleBand().range([0, WIDTH]).padding([0.15])
    vis.xAxisGroup = vis.g
      .append("g")
      .attr("transform", `translate(0, ${HEIGHT})`) // translates it to the bottom of the svg canvas

    // Scale y-axis
    vis.y = d3.scaleLinear().range([HEIGHT, 0]) //Min and max of the grid
    vis.yAxisGroup = vis.g.append("g")

    // X-axis Label
    vis.g
      .append("text")
      .attr("x", WIDTH / 2)
      .attr("y", HEIGHT + 40)
      .attr("font-size", 20)
      .attr("font-weight", "bold")
      .attr(
        "font-family",
        "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif"
      )
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .text("Year")

    // Y-axis Label
    vis.yAxisLabel = vis.g
      .append("text")
      .attr("x", -(HEIGHT / 2))
      .attr("y", -65)
      .attr("transform", "rotate(-90)")
      .attr("font-size", 20)
      .attr("font-weight", "bold")
      .attr(
        "font-family",
        "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif"
      )
      .attr("text-anchor", "middle")
      .attr("fill", "black")

    vis.yAxisLabel.text(`Acreage (ha)`)

    // vis.update(data)
  }

  update(data, dataState, isPercentage) {
    let vis = this
    vis.data = data

    var dataUnit = "ha"
    if (isPercentage) {
      data.forEach(year => {
        year.noncrop_ha = (year.noncrop_ha / year.total_ha) * 100
        year.onset_ha = (year.onset_ha / year.total_ha) * 100
        year.peren_ha = (year.peren_ha / year.total_ha) * 100
      })
      dataUnit = "%"
    }

    vis.yAxisLabel.text(`Acreage (${dataUnit})`)

    vis.g.selectAll(".tooltipGroup").remove()

    var subgroup_keys = ["onset_ha", "peren_ha", "noncrop_ha"]

    var subgroups = Object.keys(vis.data[0]).filter(key => {
      if (dataState) {
        subgroup_keys = Object.keys(dataState).filter(key => dataState[key])
      }
      return subgroup_keys.includes(key)
    })

    var groups = d3.map(vis.data, d => d.year).keys()

    vis.x.domain(groups)
    vis.xAxisGroup.call(d3.axisBottom(vis.x).tickSize(0))

    vis.y.domain([
      0,
      d3.max(vis.data, d => {
        return d3.max([
          d[subgroup_keys[0]],
          d[subgroup_keys[1]],
          d[subgroup_keys[2]],
        ])
      }),
    ])
    vis.yAxisGroup.transition().duration(500).call(d3.axisLeft(vis.y))

    var xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, vis.x.bandwidth()])
      .padding([0.05])

    // JOIN - connect empty set of elements (points, circles) with the data
    // const rects = vis.g.append("g").selectAll("g").data(vis.data)
    const rects = vis.g.append("g").selectAll("rect").data(vis.data)

    // EXIT - remove old elements from the screen
    // rects.exit().attr("height", 0).attr("y", HEIGHT).remove()
    vis.g
      .selectAll("rect")
      .transition()
      .duration(500)
      .attr("height", 0)
      .attr("y", HEIGHT)
      .remove()

    // UPDATE - rearrange existing circles with new position
    rects
      .transition()
      .delay(500)
      .duration(500)
      .attr("x", d => xSubgroup(d.key))
      .attr("y", d => vis.y(d.value))
      .attr("width", xSubgroup.bandwidth())
      .attr("height", d => HEIGHT - vis.y(d.value))

    // ENTER - enter new circles in the screen
    var rect_data = rects
      .enter()
      .append("g")
      .attr("transform", d => "translate(" + vis.x(d.year) + ",0)")
      .selectAll("rect")
      .data(d =>
        subgroups.map(key => {
          return { key: key, value: d[key] }
        })
      )
      .enter()
      .append("rect")
      .attr("x", d => xSubgroup(d.key))
      .attr("width", xSubgroup.bandwidth())
      .attr("fill", d => {
        if (d.key === "peren_ha") {
          return "#1f6e04"
        } else if (d.key === "onset_ha") {
          return "#4b0596"
        } else if (d.key === "noncrop_ha") {
          return "#006097"
        }
      })

    rect_data
      .attr("y", d => vis.y(0))
      .attr("height", 0)
      .transition()
      .delay(500)
      .duration(500)
      .attr("y", d => vis.y(d.value))
      .attr("height", d => HEIGHT - vis.y(d.value))
    rect_data.on("mouseover", d => drawTooltip(d)).on("mouseout", removeTooltip)

    const tooltipWidth = 65
    const tooltipHeight = 40
    const tooltipGroup = vis.g.append("g").attr("class", "tooltipGroup")

    const tooltip = tooltipGroup
      .append("rect")
      .attr("width", tooltipWidth)
      .attr("height", tooltipHeight)
      .attr("fill", "none")
      .attr("rx", 2)
      .attr("ry", 2)
      .attr("class", "tooltipGroup")

    const tooltipLine = tooltipGroup.append("line")
    const tooltipHead = tooltipGroup
      .append("text")
      .style("fill", "white")
      .attr("font-size", "10px")
      .attr("font-weight", "bold")

    const tooltipText = tooltipGroup
      .append("text")
      .style("fill", "white")
      .attr("font-size", "10px")

    function drawTooltip(barData) {
      tooltipLine
        .attr("stroke", "black")
        .attr("x1", 0)
        .attr("x2", WIDTH)
        .transition()
        .duration(500)
        .attr("y1", vis.y(barData.value))
        .attr("y2", vis.y(barData.value))
        .attr("stroke", () => {
          if (barData.key === "peren_ha") {
            return "#1f6e04"
          } else if (barData.key === "onset_ha") {
            return "#4b0596"
          } else if (barData.key === "noncrop_ha") {
            return "#006097"
          }
        })

      tooltip
        .attr("x", -tooltipWidth)
        .attr("stroke", "white")
        .transition()
        .duration(500)
        .attr("y", vis.y(barData.value))
        .attr("fill", () => {
          if (barData.key === "peren_ha") {
            return "#1f6e04"
          } else if (barData.key === "onset_ha") {
            return "#4b0596"
          } else if (barData.key === "noncrop_ha") {
            return "#006097"
          }
        })

      tooltipHead
        .text(() => {
          if (barData.key === "peren_ha") {
            return "Perennial"
          } else if (barData.key === "onset_ha") {
            return "Annual Crop"
          } else if (barData.key === "noncrop_ha") {
            return "Non-Crop"
          }
        })
        .attr("x", -tooltipWidth * 0.95)
        .transition()
        .duration(500)
        .attr("y", vis.y(barData.value) + tooltipHeight * 0.4)

      var tooltipTextType
      if (isPercentage) {
        tooltipTextType = tooltipText.text(
          Number.parseFloat(barData.value).toPrecision(2).toLocaleString() +
            dataUnit
        )
      } else {
        tooltipTextType = tooltipText.text(
          parseInt(Math.round(barData.value)).toLocaleString() + dataUnit
        )
      }

      tooltipTextType
        .attr("x", -tooltipWidth * 0.95)
        .transition()
        .duration(500)
        .attr("y", vis.y(barData.value) + tooltipHeight * 0.85)

      d3.select(this)
        .style("stroke", "darkslategrey")
        .style("stroke-width", "2px")
        .style("stroke-opacity", "1")
    }

    function removeTooltip() {
      if (tooltipLine) {
        tooltipLine.attr("stroke", "none")
        tooltipText.attr("fill", "none")
        tooltipHead.attr("fill", "none")
        tooltip.attr("fill", "none")
      }

      d3.select(this)
        .transition()
        .duration(250)
        .attr("fill", d => {
          if (d.key === "peren_ha") {
            return "#1f6e04"
          } else if (d.key === "onset_ha") {
            return "#4b0596"
          } else if (d.key === "noncrop_ha") {
            return "#006097"
          }
        })
        //.style("opacity", "1")
        .style("stroke-opacity", "0")
    }
  }
}

export default D3Chart
