import * as d3 from "d3"

const MARGIN = { TOP: 10, BOTTOM: 50, LEFT: 80, RIGHT: 10 }
const WIDTH = 500 - MARGIN.LEFT - MARGIN.RIGHT
const HEIGHT = 300 - MARGIN.TOP - MARGIN.BOTTOM

function dateFromDay(d) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  var date = new Date(2000, 0) // initialize a date in `2000-01-01`
  var completeDate = new Date(date.setDate(d)) // add the number of days
  var month = completeDate.getMonth()
  var day = completeDate.getDate()

  return monthNames[month] + " " + day
}

class D3Chart {
  constructor(element, data) {
    let vis = this
    vis.data = data

    function responsivefy(svg) {
      // get container + svg aspect ratio
      var container = d3.select(svg.node().parentNode),
        width = parseInt(svg.style("width")),
        height = parseInt(svg.style("height")),
        aspect = width / height

      // add viewBox and preserveAspectRatio properties,
      // and call resize so that svg resizes on inital page load
      svg
        .attr("viewBox", "0 0 " + width + " " + height)
        .attr("perserveAspectRatio", "xMinYMid")
        .call(resize)

      // to register multiple listeners for same event type,
      // you need to add namespace, i.e., 'click.foo'
      // necessary if you call invoke this function for multiple svgs
      // api docs: https://github.com/mbostock/d3/wiki/Selections#on
      d3.select(window).on("resize." + container.attr("id"), resize)

      // get width of container and resize svg to fit it
      function resize() {
        var targetWidth = parseInt(container.style("width"))
        svg.attr("width", targetWidth)
        svg.attr("height", Math.round(targetWidth / aspect))
      }
    }

    // Setup chart dimensions
    vis.g = d3
      .select(element)
      .append("svg")
      .attr("id", "lineChart")
      .attr("width", WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
      .attr("height", HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
      .call(responsivefy)
      .append("g")
      .attr("transform", `translate(${MARGIN.LEFT}, ${MARGIN.TOP})`)

    vis.x = d3.scaleLinear().range([0, WIDTH])
    vis.xAxisGroup = vis.g
      .append("g")
      .attr("transform", `translate(0, ${HEIGHT})`) // translates it to the bottom of the svg canvas

    vis.x.domain([1, d3.max(vis.data, d => Number(d.day))])

    // Scale y-axis
    vis.y = d3.scaleLinear().range([HEIGHT, 0 + 10]) //Min and max of the grid
    vis.yAxisGroup = vis.g.append("g")
    vis.y.domain([0, d3.max(vis.data, d => Number(d.cumulative_onset))]) // Min and max of the dataset

    // X-axis Label
    vis.g
      .append("text")
      .attr("x", WIDTH / 2)
      .attr("y", HEIGHT + 40)
      .attr("font-size", 20)
      .attr("font-weight", "bold")
      .attr("font-family","-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif")
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .text("Day")

    // Y-axis Label
    vis.g
      .append("text")
      .attr("x", -(HEIGHT / 2))
      .attr("y", -60)
      .attr("transform", "rotate(-90)")
      .attr("font-size", 20)
      .attr("font-weight", "bold")
      .attr("font-family","-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif")
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .text("Onset (ha)")

    vis.g
      .append("defs")
      .append("svg:clipPath")
      .attr("id", "clip")
      .append("svg:rect")
      .attr("width", WIDTH)
      .attr("height", HEIGHT)
      .attr("x", 0)
      .attr("y", 0)
  }

  update(data) {
    let vis = this
    vis.data = data

    // remove all clip paths before rendering new path
    vis.g.selectAll("#clipPath").remove()

    // Scale x-axis
    // vis.x.domain([0, d3.max(vis.data, d => Number(d.day))])
    vis.xAxisGroup.call(d3.axisBottom(vis.x).ticks(5).tickFormat(dateFromDay))

    vis.y.domain([0, d3.max(vis.data, d => Number(d.cumulative_onset))]) // Min and max of the dataset
    vis.yAxisGroup.call(d3.axisLeft(vis.y))

    // group the data: I want to draw one line per group
    var yearStat = d3
      .nest() // nest function allows to group the calculation per level of a factor
      .key(function (d) {
        return d.year
      })
      .entries(vis.data)

    const clipPath = vis.g
      .append("g")
      .attr("clip-path", "url(#clip)")
      .attr("id", "clipPath")

    // Add the brushing
    const brush = d3
      .brushX() // Add the brush feature using the d3.brush function
      .extent([
        [1, 0],
        [WIDTH + 1, HEIGHT],
      ])
      .on("end", () => {
        updateChart()
      }) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area

    clipPath.append("g").attr("class", "brush").call(brush)
    clipPath
      .selectAll(".brush")
      .data(vis.data)
      .on("mouseenter", drawTooltip)
      .on("mousemove", drawTooltip)
      .on("mouseleave", removeTooltip)
      
    vis.g.on("mouseleave", () => {
      if (tooltip) tooltip.style("display", "none").transition().duration(500)
    })

    // JOIN - connect empty set of elements (points, circles) with the data
    const lines = clipPath.selectAll(".line").data(yearStat)

    // UPDATE - rearrange existing paths with new position
    lines.attr("d", function (d) {
      return d3
        .line()
        .x(function (d) {
          return vis.x(d.day)
        })
        .y(function (d) {
          return vis.y(+d.cumulative_onset)
        })(d.values)
    })

    // ENTER - enter new circles in the screen
    lines
      .enter()
      .append("path")
      .attr("class", "onsetLine")
      .attr("fill", "none")
      .attr("stroke", function (d) {
        switch (d.key) {
          case "2017":
            return "#003f5c"
          case "2018":
            return "#7a5195"
          case "2019":
            return "#ef5675"
          default:
            return "#ffa600"
        }
      })
      .attr("stroke-width", 1.5)
      
      .attr("d", function (d) {
        return d3
          .line()
          .x(function (d) {
            return vis.x(d.day)
          })
          .y(function (d) {
            return vis.y(+d.cumulative_onset)
          })(d.values)
      })
      .attr("id", function (d) {
        return d.key
      })

    var idleTimeout
    function idled() {
      idleTimeout = null
    }

    function updateChart() {
      const extent = d3.event

      if (!extent.selection) {
        if (!idleTimeout) return (idleTimeout = setTimeout(idled, 350)) // This allows to wait a little bit
        vis.y.domain([0, d3.max(vis.data, d => Number(d.cumulative_onset))]) // Min and max of the dataset
        vis.x.domain([0, d3.max(vis.data, d => Number(d.day))])
      } else {
        const selection = extent.selection
        vis.x.domain([vis.x.invert(selection[0]), vis.x.invert(selection[1])])
        vis.y.domain([0, d3.max(vis.data, d => Number(d.cumulative_onset))]) // Min and max of the dataset
        clipPath.select(".brush").call(brush.move, null) // This remove the grey brush area as soon as the selection has been done
      }
      // update axis
      vis.xAxisGroup
        .transition()
        .duration(1000)
        .call(d3.axisBottom(vis.x).ticks(5).tickFormat(dateFromDay))

      vis.yAxisGroup.transition().duration(1000).call(d3.axisLeft(vis.y))

      clipPath
        .selectAll("path")
        .transition()
        .duration(1000)
        .attr("d", function (d) {
          return d3
            .line()
            .x(function (d) {
              return vis.x(d.day)
            })
            .y(function (d) {
              return vis.y(+d.cumulative_onset)
            })(d.values)
        })
    }
    const tooltip = d3.select("#tooltip")
    const tooltipLine = vis.g.append("line")

    function removeTooltip() {
      // if (tooltip) tooltip.style("display", "none").transition().duration(500)
      if (tooltipLine) tooltipLine.attr("stroke", "none")
    }

    function drawTooltip() {
      var x = d3.mouse(clipPath.node())[0]
      var y = d3.mouse(clipPath.node())[1]

      var tooltipX = x + 110
      var tooltipY = y + 40
      var day = Math.floor(vis.x.invert(x))

      if ((x / (WIDTH + MARGIN.LEFT + MARGIN.RIGHT)) * 100 >= 35) {
        tooltipX = 100 - (x / (WIDTH + MARGIN.LEFT + MARGIN.RIGHT)) * 100 - 5
        tooltip.style("right", `${tooltipX}%`).style("left", "auto")
      } else {
        tooltipX = (x / (WIDTH + MARGIN.LEFT + MARGIN.RIGHT)) * 100 + 25
        tooltip.style("left", `${tooltipX}%`).style("right", "auto")
      }

      if (y >= 130) {
        tooltipY = 100 - (y / (HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)) * 100 - 5
        tooltip.style("bottom", `${tooltipY}%`).style("top", "auto")
      } else {
        tooltipY = (y / (HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)) * 100
        tooltip.style("top", `${tooltipY}%`).style("bottom", "auto")
      }

      tooltipLine
        .attr("stroke", "black")
        .attr("x1", vis.x(day))
        .attr("x2", vis.x(day))
        .attr("y1", 0)
        .attr("y2", HEIGHT)

      tooltip
        .html(`<h6>${dateFromDay(day)}</h6>`)
        .style("display", "block")
        .append("Card.Body")
        .append("Table")
        .attr("class", "table-sm tooltip-table")
        .append("tbody")
        .selectAll()
        .data(yearStat)
        .enter()
        .append("tr")
        .html(d => {
          if (d.values.find(h => h.day === day)) {
            return `<td><div id="color_${
              d.key
            }" class="color-box"></div></td><th>${d.key}</th><td>${Math.floor(
              d.values.find(h => h.day === day).cumulative_onset
            ).toLocaleString()} ha</td>`
          }
        })
    }
  }
}

export default D3Chart
