import React, { Component } from "react"
import { Row, Col } from "react-bootstrap"
import Map from "./map"
import "./profile-tabs.scss"
import Button from "react-bootstrap/Button"
import LineChart from "./profile/line-chart/line"
import Background from "./profile/background"
import BarChart from "./profile/bar-chart/bar-chart"
import Loading from "./loading"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import BarChartControls from "./profile/bar-chart/bar-chart-controls"
import ContextCard from "./context-card"
import Image from "react-bootstrap/Image"
import Popover from "react-bootstrap/Popover"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import { StaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import fetch from "cross-fetch"

import { connect } from "react-redux"

const mapStateToProps = ({
  data_section,
  id,
  infoCardIsVisible,
  infoCardType,
}) => {
  return { data_section, id, infoCardIsVisible, infoCardType }
}

const mapDispatchToProps = dispatch => {
  return {
    select_tab: tab => dispatch({ type: `SELECT_TAB`, id: tab }),
    open_infocard: contentType =>
      dispatch({ type: `OPEN_INFOCARD`, contentType }),
  }
}

class ProfileTabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      onsetData: [],
      acreageData: [],
      acreageDataState: null,
      acreageContext: [],
      onsetContext: [],
      isLoading: true,
      backgroundData: "",
      name: "",
    }
  }

  componentWillMount() {
    fetch(
      `https://bigdata-in-ag.uc.r.appspot.com/regions?region_id=${this.props.context.id}`
    )
      .then(response => response.json())
      .then(data => {
        if (data[0].region_contexts.length > 0) {
          this.props.select_tab(1)
        } else {
          this.props.select_tab(2)
        }
        this.setState({
          onsetData: data[0].on_d_ha,
          acreageData: data[0].acreage,
          acreageContext: data[0].acreage_contexts,
          onsetContext: data[0].onset_contexts,
          isLoading: false,
          name: data[0].name,
          backgroundData: data[0].region_contexts,
        })
      })
  }

  updateOnsetData = data => {
    this.setState({
      onsetData: data,
    })
  }

  handleInfoCardOpen = e => {
    this.props.open_infocard(e.target.value)
  }

  render() {
    const {
      onsetData,
      acreageData,
      isLoading,
      backgroundData,
      name,
      acreageContext,
      onsetContext,
    } = this.state

    const { infoCardIsVisible, infoCardType } = this.props

    // const popover = (
    //   <Popover id="popover-basic">
    //     <Popover.Title as="h3">
    //       {infoCardType === "acreage"
    //         ? "Acreage"
    //         : infoCardType === "onset"
    //         ? "Onset"
    //         : ""}
    //     </Popover.Title>
    //     <Popover.Content>
    //       <StaticQuery
    //         query={graphql`
    //           query {
    //             strapiProfilePage {
    //               acreage_info
    //               onset_info
    //             }
    //           }
    //         `}
    //         render={data => (
    //           <ReactMarkdown
    //             source={
    //               infoCardType === "acreage"
    //                 ? data.strapiProfilePage.acreage_info
    //                 : infoCardType === "onset"
    //                 ? data.strapiProfilePage.onset_info
    //                 : ""
    //             }
    //           />
    //         )}
    //       />
    //     </Popover.Content>
    //   </Popover>
    // )

    if (isLoading) {
      return <Loading />
    } else {
      return (
        <div className="profile-layout">
          <Row md={12} lg={12}>
            <Col md={5} lg={5}>
              <h1>{name}</h1>
              <div className="map-container">
                {infoCardIsVisible ? (
                  <ContextCard
                    data={
                      infoCardType === "acreage" ? acreageContext : onsetContext
                    }
                  />
                ) : (
                  <Map />
                )}
                {/* <Map /> */}
                {/* <ContextCard /> */}
              </div>
            </Col>
            <Col md={7} lg={7}>
              <Container className="profile">
                <Row>
                  {backgroundData.length > 0 ? (
                    <Col className="profile-tab">
                      <button
                        onClick={() => this.props.select_tab(1)}
                        className={
                          this.props.data_section === 1
                            ? "tab-btn tab-btn-active"
                            : "tab-btn tab-btn-notActive"
                        }
                      >
                        Background
                      </button>
                    </Col>
                  ) : null}

                  <Col className="profile-tab">
                    <button
                      onClick={() => this.props.select_tab(2)}
                      className={
                        this.props.data_section === 2
                          ? "tab-btn tab-btn-active"
                          : "tab-btn tab-btn-notActive"
                      }
                    >
                      Acreage
                    </button>
                  </Col>
                  <Col className="profile-tab">
                    <button
                      onClick={() => this.props.select_tab(3)}
                      className={
                        this.props.data_section === 3
                          ? "tab-btn tab-btn-active"
                          : "tab-btn tab-btn-notActive"
                      }
                    >
                      Onset
                    </button>
                  </Col>

                  {/* <Button onClick={() => this.props.select_tab(4)}>
                  Production
                </Button> */}
                </Row>
                <Row>
                  <Col>
                    <StaticQuery
                      query={graphql`
                        query {
                          strapiProfilePage {
                            acreage_info
                            onset_info
                          }
                        }
                      `}
                      render={data => (
                        <div className="dashboardContent">
                          {this.props.data_section === 1 ? (
                            <Background data={backgroundData} />
                          ) : this.props.data_section === 2 ? (
                            <div style={{ width: "100%" }}>
                              <Card className="data-card">
                                <Card.Body>
                                  <Card.Title>
                                    {/* <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  placement="left-start"
                                  overlay={popover}
                                >
                                  <Image
                                    src="./assets/help_icon.svg"
                                    className="icon"
                                    rounded
                                  />
                                </OverlayTrigger> */}
                                    Acreage Distribution
                                  </Card.Title>

                                  <BarChartControls />

                                  <BarChart data={acreageData} />
                                </Card.Body>
                                <Card.Footer className="text-muted card-footer">
                                  <ReactMarkdown
                                    source={data.strapiProfilePage.acreage_info}
                                  />
                                </Card.Footer>
                              </Card>
                              {acreageContext.length > 0 ? (
                                <Button
                                  onClick={this.handleInfoCardOpen}
                                  value="acreage"
                                >
                                  More Info
                                </Button>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : this.props.data_section === 3 ? (
                            <div style={{ width: "100%" }}>
                              <Card className="data-card">
                                <Card.Body>
                                  <Card.Title>
                                    {/* <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  placement="left-start"
                                  overlay={popover}
                                >
                                  <Image
                                    src="./assets/help_icon.svg"
                                    className="icon"
                                    rounded
                                  />
                                </OverlayTrigger> */}
                                    Cumulative Onset
                                  </Card.Title>
                                  <LineChart data={onsetData} />
                                </Card.Body>
                                <Card.Footer className="text-muted card-footer">
                                  <ReactMarkdown
                                    source={data.strapiProfilePage.onset_info}
                                  />
                                </Card.Footer>
                              </Card>
                              {onsetContext.length > 0 ? (
                                <Button
                                  onClick={this.handleInfoCardOpen}
                                  value="onset"
                                >
                                  More Info
                                </Button>
                              ) : (
                                ""
                              )}

                              {/* <LineChartControls
                          updateData={this.updateOnsetData}
                          data={this.props.context.on_d_ha}
                        /> */}
                            </div>
                          ) : (
                            <div>{/* <HorizontalBarChart /> */}</div>
                          )}
                        </div>
                      )}
                    />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </div>
      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileTabs)
