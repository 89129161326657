import React, { Component } from "react"
import Card from "react-bootstrap/Card"
import "./context-card.scss"
import { connect } from "react-redux"
import ReactMarkdown from "react-markdown"

const mapStateToProps = ({ infoCardIsVisible, infoCardType }) => {
  return { infoCardIsVisible, infoCardType }
}

const mapDispatchToProps = dispatch => {
  return {
    close_infoCard: () =>
      dispatch({
        type: `CLOSE_INFOCARD`,
      }),
  }
}

class ContextCard extends Component {
  handleClose = () => {
    this.props.close_infoCard()
  }

  render() {
    const { infoCardType, data } = this.props
    return (
      <Card className="context-card">
        <Card.Header className="context-card-header">
          <h5>
            {infoCardType === "acreage" ? "Acreage Distribution" : "Onset"}
          </h5>
          <button className="close" onClick={this.handleClose}>
            &times;
          </button>
        </Card.Header>
        <Card.Body className="context-card-body">
          {data.map(context => {
            let updated_date = new Date(Date.parse(context.updated_at))
            const monthNames = [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ]
            var dd = updated_date.getDate()
            var month = monthNames[updated_date.getMonth()]
            var yyyy = updated_date.getFullYear()
            return (
              <div key={context.id}>
                <Card.Title>{context.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Last updated on {month} {dd}, {yyyy}
                </Card.Subtitle>
                <ReactMarkdown source={context.background} />
              </div>
            )
          })}
        </Card.Body>
      </Card>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextCard)
