import React, { Component } from "react"

import "./bar-chart-controls.scss"

import { connect } from "react-redux"

const mapStateToProps = ({ peren_ha, onset_ha, noncrop_ha, isPercentage }) => {
  return { peren_ha, onset_ha, noncrop_ha, isPercentage }
}

const mapDispatchToProps = dispatch => {
  return {
    set_peren_ha: dataState =>
      dispatch({ type: `PEREN_HA_STATE`, dataState: dataState }),
    set_onset_ha: dataState =>
      dispatch({ type: `ONSET_HA_STATE`, dataState: dataState }),
    set_noncrop_ha: dataState =>
      dispatch({ type: `NONCROP_HA_STATE`, dataState: dataState }),
    set_acreage: dataState =>
      dispatch({ type: `ACREAGE_DISPLAY_STATE`, isPercentage: dataState }),
  }
}
class ChartControl extends Component {
  constructor(props) {
    super(props)
    this.state = {
      peren_ha: this.props.peren_ha,
      onset_ha: this.props.onset_ha,
      noncrop_ha: this.props.noncrop_ha,
      isPercentage: this.props.isPercentage,
    }
  }

  handleDisplayToggle = e => {
    this.setState(
      {
        isPercentage: !this.state.isPercentage,
      },
      () => {
        this.props.set_acreage(this.state.isPercentage)
      }
    )
  }

  handleToggle = e => {
    if (e.target.value === "perennial") {
      this.setState(
        {
          peren_ha: !this.state.peren_ha,
        },
        () => {
          this.props.set_peren_ha(this.state.peren_ha)
        }
      )
    } else if (e.target.value === "annualcrop") {
      this.setState(
        {
          onset_ha: !this.state.onset_ha,
        },
        () => {
          this.props.set_onset_ha(this.state.onset_ha)
        }
      )
    } else if (e.target.value === "noncrop") {
      this.setState(
        {
          noncrop_ha: !this.state.noncrop_ha,
        },
        () => {
          this.props.set_noncrop_ha(this.state.noncrop_ha)
        }
      )
    }
  }

  render() {
    const { isPercentage } = this.props
    return (
      <div className="bar-chart-control-panel">
        <div className="bar-chart-display-section">
          <div
            className="bar-chart-display-text"
            id="left-display-text"
            style={{
              borderBottomColor: !isPercentage
                ? "darkslategray"
                : "rgba(0,0,00,0)",
            }}
          >
            Total
          </div>
          <button className="switch" onClick={this.handleDisplayToggle}>
            <input type="checkbox" checked={isPercentage} readOnly aria-label="total-percent-toggle"/>
            <span className="slider"></span>
          </button>
          <div
            className="bar-chart-display-text"
            id="right-display-text"
            style={{
              borderBottomColor: isPercentage
                ? "darkslategray"
                : "rgba(0,0,00,0)",
            }}
          >
            Percentage
          </div>
        </div>
        <div className="bar-chart-legend">
          <div className="bar-chart-legend-section">
            <button
              className="bar-chart-legend-box"
              id="perennial-toggle"
              onClick={this.handleToggle}
              aria-label="peren-toggle"
              style={{
                backgroundColor: this.props.peren_ha ? "#1f6e04" : "white",
              }}
              value="perennial"
            ></button>
            <div className="bar-chart-legend-text">Perennial</div>
          </div>
          <div className="bar-chart-legend-section">
            <button
              className="bar-chart-legend-box"
              id="annualcrop-toggle"
              onClick={this.handleToggle}
              aria-label="annualcrop-toggle"
              style={{
                backgroundColor: this.props.onset_ha ? "#4b0596" : "white",
              }}
              value="annualcrop"
            ></button>
            <div className="bar-chart-legend-text">Annual Crop</div>
          </div>
          <div className="bar-chart-legend-section">
            <button
              className="bar-chart-legend-box"
              id="noncrop-toggle"
              onClick={this.handleToggle}
              aria-label="noncrop-toggle"
              style={{
                backgroundColor: this.props.noncrop_ha ? "#006097" : "white",
              }}
              value="noncrop"
            ></button>
            <div className="bar-chart-legend-text">Non-Crop</div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartControl)
