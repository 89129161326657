import React, { Component } from "react"
import ReactMarkdown from "react-markdown"

export default class Background extends Component {
  render() {
    return (
      <div>
        {this.props.data.map(background => {
          return <ReactMarkdown source={background.background} key={background.id}/>
        })}
      </div>
    )
  }
}
